import { useState } from "react";
import { makeStyles, Modal, Popover } from "@material-ui/core";
import { Close, Journal } from "../assets/images/svgs";
import store from "store2";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useLongPress } from "../helpers/hooks";

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    width: "100%",
  },
  paper: {
    maxWidth: 250,
    width: "100%",
  },
});

const JournalCard = ({ item }) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pickedEl, setPickedEl] = useState({});
  const [deleteEl, setDeleteEl] = useState(false);

  const queryClient = useQueryClient();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteJournal = async () => {
    const journals = await store.get("journals");
    const filteredJournals = journals.filter(
      (profile) => profile.id !== pickedEl.id
    );
    await store.set("journals", filteredJournals);
    await store("activeJournal", {});
    queryClient.invalidateQueries("journals");
    queryClient.invalidateQueries("activeJournal");
    setDeleteEl(false);
    handleClose();
  };

  const classes = useStyles();

  const handleGoTo = async () => {
    if (item.active) {
      history.push("/aktivni-dnevnik");
    } else {
      await store("archivedJournal", item);
      history.push("/arhivirani-dnevnik");
    }
  };
  const handlePress = (e) => {
    e.preventDefault();
    console.log("long press", item);
    handleClick(e);
    setPickedEl(item);
  };
  const longPressEvent = useLongPress(handlePress, handleGoTo);

  return (
    <div className="w-full">
      <div
        className="card-xs w-full flex my-2 h-24 select-none"
        {...longPressEvent}
      >
        <div className="w-3/12 flex items-center">
          <div className="w-14">
            <Journal className="h-14" />
          </div>
        </div>
        <div className="w-9/12">
          <h4 className="font-semibold text-md text-darkT-500">
            Dnevnik profila: {item.profil.label}
          </h4>
          <h5 className="text-sm text-darkT-200">
            Datum: {dayjs(item.datumPocetka).format("DD.MM.YYYY")}
          </h5>
          <h5 className="text-sm text-darkT-200">Broj dana: {item.brojDana}</h5>
          <h5 className="text-sm text-darkT-200">
            Status: {item.active ? "aktivan" : "arhiviran"}
          </h5>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{ root: classes.root, paper: classes.paper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="w-full max-w-md select-none">
          {/* <div
            className="p-2 hover:bg-blue-600 hover:text-white cursor-pointer"
            onClick={setEdit}
          >
            Uredi
          </div> */}
          <div
            className="p-2 hover:bg-red-600 hover:text-white cursor-pointer"
            onClick={() => setDeleteEl(true)}
          >
            Obriši
          </div>
          <div
            className="p-2 hover:bg-gray-600 hover:text-white cursor-pointer"
            onClick={handleClose}
          >
            Odustani
          </div>
        </div>
      </Popover>
      {pickedEl.id && deleteEl ? (
        <Modal open={deleteEl}>
          <div
            style={{ marginTop: "70%" }}
            className="px-2 outline-none select-none"
          >
            <div className="flex justify-center flex-col bg-white p-2 rounded">
              <div className="flex self-end">
                <div className="p-2">
                  <Close
                    className="cursor-pointer w-5 h-5"
                    onClick={() => setDeleteEl(false)}
                  />
                </div>
              </div>
              <h5 className="font-semibold text-lg">
                Jeste li sigurni da želite{" "}
                {pickedEl.active ? "otkazati i" : null} obrisati{" "}
                {pickedEl.active ? "aktivni" : "arhivirani"} dnevnik?
              </h5>
              <div>Profil: {pickedEl.profil.label}</div>
              <div className="mt-1">
                Datum: {dayjs(pickedEl.datumPocetka).format("DD.MM.YYYY")}
              </div>
              <div>Broj dana: {pickedEl.brojDana}</div>
              <div className="mx-auto mt-3 flex gap-2">
                <button
                  className="btn btn-danger mx-auto"
                  onClick={deleteJournal}
                >
                  Obriši
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => setDeleteEl(false)}
                >
                  Odustani
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
export default JournalCard;
