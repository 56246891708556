import { nanoid } from "nanoid";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import store from "store2";
import { Profiles2, Add } from "../../assets/images/svgs";
import ProfileCard from "../../components/ProfileCard";

const Profiles = () => {
  const { data, isFetching } = useQuery(
    "profiles",
    async () => {
      const res = await store.get("profiles");
      return res ? res : [];
    },
    {}
  );

  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">Profili</h2>
      <div className="max-w-255 flex items-center mx-auto mt-11">
        <Profiles2 width="255px" />
      </div>
      <div>
        <h5 className="text-sm text-darkT-200 text-center">
          Don’t worry, It’s easy & takes only a few minutes
        </h5>
      </div>
      <div className="w-full mx-auto flex flex-wrap mt-6">
        {isFetching ? (
          <div></div>
        ) : (
          data &&
          data.length > 0 &&
          data.map((item) => {
            return <ProfileCard item={item} key={nanoid()} />;
          })
        )}
      </div>
      <div className="flex mt-auto mb-0">
        <div className="my-4 w-full">
          <Link to="/novi-profil">
            <button className="btn btn-primary w-full relative flex">
              <Add className="absolute self-center" />
              <p className="mx-auto">Napravi novi profil</p>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Profiles;
