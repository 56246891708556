import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import store from "store2";
import CustomSelect from "../../components/CustomSelect";
import Datepicker from "../../components/DatePicker";

const NewProfile = ({ from, close, setProfile, refetch }) => {
  let history = useHistory();
  const editProfile = store.get("editProfile");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: editProfile });

  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    setLoading(true);
    const id = nanoid();
    if (editProfile && editProfile.id) {
      const profile = Object.assign(
        {},
        store.get("profiles").find((item) => item.id === editProfile.id)
      );
      await store(
        "profiles",
        store("profiles").filter((item) => item.id !== editProfile.id)
      );
      await store.add("profiles", [{ ...profile, ...data }]);
    } else {
      await store.add("profiles", [{ ...data, id }]);
    }
    if (from === "newJournal") {
      setProfile("profil", { value: { ...data, id }, label: data.ime });
      await store("newProfile", { value: { ...data, id }, label: data.ime });
      await refetch();
      close();
    } else {
      history.push("/profili");
    }
  };

  useEffect(() => {
    return () => {
      store("editProfile", {});
    };
  }, []);

  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">
        {editProfile && editProfile.id ? "Uredi profil" : "Novi profil"}
      </h2>

      <div className="w-full mx-auto flex flex-wrap mt-6">
        <form className="w-full px-2" onSubmit={handleSubmit(submit)}>
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="ime"
            >
              Ime
            </label>
            <Controller
              name="ime"
              render={({ field }) => (
                <input
                  {...field}
                  className="input"
                  id="ime"
                  type="text"
                  placeholder="Ime"
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: "Unesi ime" }}
            />

            {errors.ime && <span className="error">{errors.ime.message}</span>}
          </div>
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="spol"
            >
              Spol
            </label>
            <Controller
              name="spol"
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  options={[
                    { value: "muski", label: "Muški" },
                    { value: "zenski", label: "Ženski" },
                  ]}
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: "Odaberi spol" }}
            />
            {errors.spol && (
              <span className="error">{errors.spol.message}</span>
            )}
          </div>
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="datumRodenja"
            >
              Datum rođenja
            </label>
            <Controller
              name="datumRodenja"
              render={({ field }) => (
                <Datepicker
                  {...field}
                  format="DD.MM.YYYY"
                  autoOk
                  disableFuture
                  error={errors.datumRacuna}
                  onChange={(date) => {
                    field.onChange(date);
                    setValue("dob", dayjs().diff(date, "year"));
                  }}
                />
              )}
              control={control}
              rules={{ required: "Unesi datum rođenja" }}
            />
            {errors.datumRodenja && (
              <span className="error">{errors.datumRodenja.message}</span>
            )}
          </div>
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="dob"
            >
              Dob
            </label>
            <Controller
              name="dob"
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  maxLength={2}
                  readOnly
                  className="input"
                  decimalSeparator={false}
                  type="text"
                  placeholder="Dob"
                  error={errors.dob}
                />
              )}
              control={control}
              rules={{ required: "Unesi datum rodenja" }}
            />
            {errors.dob && <span className="error">{errors.dob.message}</span>}
          </div>
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="masa"
            >
              Tjelesna masa
            </label>
            <div className="relative">
              <Controller
                name="masa"
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    maxLength={3}
                    className="input"
                    decimalSeparator={false}
                    type="text"
                    inputMode="numeric"
                    placeholder="Tjelesna masa"
                    error={errors.masa}
                  />
                )}
                control={control}
                rules={{ required: "Unesi tjelesnu masu" }}
              />
              <span className=" absolute right-0 bottom-0 top-0 text-lg p-4 text-black dark:text-white">
                kg
              </span>
            </div>

            {errors.masa && (
              <span className="error">{errors.masa.message}</span>
            )}
          </div>
        </form>
      </div>
      <div className="flex mt-auto ">
        <div className="my-4 w-full">
          <button
            disabled={loading}
            onClick={handleSubmit(submit)}
            className="btn btn-primary w-full text-center mx-auto"
          >
            {editProfile && editProfile.id
              ? "Spremi promjene"
              : "Napravi profil"}
          </button>
        </div>
      </div>
    </>
  );
};

export default NewProfile;
