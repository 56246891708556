import { useState } from "react";
import Accordion from "../../components/Accordion";

const Guides = () => {
  const [picked, setPicked] = useState(0);
  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">Upute</h2>
      <div className="w-full mx-auto flex flex-wrap">
        <div className="flex justify-center items-start my-2">
          <div className="w-full sm:w-10/12 md:w-1/2 my-1">
            <ul className="flex flex-col">
              <Accordion
                id={1}
                pickedID={picked}
                setPicked={setPicked}
                title="Izrada profila"
                text={
                  <p className="p-3 text-gray-900 text-justify">
                    Potrebno je izraditi profil prije ispunjavanja dnevnika.
                    Profil je jedinstven za svakog korisnika dnevnika, tj. ne
                    mogu postojati dva profila s istim imenom. Potrebni podaci
                    za izradu profila su: ime, spol, datum rođenja, tjelesna
                    masa. Ime profila ne mora nužno odgovarati imenu korisnika.
                    Datum rođenja je potreban kako bi se izračunala dob i
                    očekivani kapacitet mokraćnog mjehura. Tjelesna masa je
                    bitna u određivanju preporučenog dnevnog unosa tekućine.
                  </p>
                }
              />
              <Accordion
                id={2}
                pickedID={picked}
                setPicked={setPicked}
                title="Ispunjavanje dnevnika pijenja i mokrenja"
                text={
                  <>
                    <p className="p-3 text-gray-900 text-justify">
                      Kod započinjanja dnevnika pijenja i mokrenja potrebno je
                      odabrati korisnički profil, broj dana trajanja dnevnika i
                      datum početka dnevnika. Moguće je odabrati današnji datum,
                      datume u prošlosti ili datume u budućnosti. Aktivni
                      dnevnik uvijek je izravno dostupan u glavnom izborniku.
                      Ukoliko se greškom započne dnevnik isti se može izbrisati
                      klikom na "Otkaži i izbriši aktivni dnevnik" u izborniku.{" "}
                    </p>
                    <p className="p-3 text-gray-900 text-justify">
                      Tijekom dana, tj. dok je osoba kojoj se ispunjava dnevnik
                      budna, potrebno je zabilježiti svako pojedinačno mokrenje
                      i pijenje. Odabirom naredbe "Dodaj mokrenje" ili "Dodaj
                      pijenje" otvorit će se upit u kojem je potrebno odabrati
                      vrijeme kad je nastupilo moikrenje ili kada je korisnik
                      pio te odabrati volumen u mililitrima.
                    </p>
                  </>
                }
              />
              <Accordion
                id={3}
                pickedID={picked}
                setPicked={setPicked}
                title="Pregled mokrenja i pijenja"
                text={
                  <>
                    <p className="p-3 text-gray-900 text-justify">
                      "Pregled mokrenja i pijenja" otvara tablični prikaz svih
                      unesenih vrijednosti. Važno je zabilježiti svako
                      pojedinačno pijenje i mokrenje kako bi se ispravno
                      izračunao stvarni kapacitet mjehura, noćna proizvodnja
                      mokraće, odredio broj dnevnik mokrenja te prikazali
                      čimbenici rizika za noćno mokrenje, tj. nevoljno mokrejne
                      u krevet tijekom sna. Kod odlaska na spavanje potrebno je
                      odabrati vrijeme i u par navrata probuditi osobu kojoj se
                      mjeri dnevnik kako bi mokrila. Najčešće je potrebno buditi
                      u dva navrata kako bi se spriječilo nevoljno mokrenje u
                      krevet. Preporučujemo buđenje 1 sat i 4 sata nakon odlaska
                      na spavanje. Nakon sna važno je zabilježiti ako je bilo
                      nezabilježenih mokrenja (poput mokrenja u krevet ili
                      smostalnih odlazaka na toalet koji nisu mjereni). Za
                      mjerenje volumena potrebno je imati mjernu posudu ili
                      nabaviti istu. Mokrenje je potrebno obavljati u mjernu
                      posudu ili tutu.
                    </p>
                  </>
                }
              />
              <Accordion
                id={4}
                pickedID={picked}
                setPicked={setPicked}
                title="Izvještaji i dijeljenje"
                text={
                  <>
                    <p className="p-3 text-gray-900 text-justify">
                      Ispunjeni dnevnik pohranjen je u Arhivi dnevnika kao
                      izvještaj. Iz izvještaja je moguće saznati stvarni
                      kapacitet mokraćnog mjehura i volumen noćne proizvodnje
                      mokraće, tj. utvrditi je li uzrok noćnog mokrenja mali
                      kapacitet mjehura ili noćna poliurija (povećana noćna
                      proizvodnja mokraće). U suradnji s liječnikom, a ovisno o
                      dobivenim vrijednostima, moguće je odabrati optimalan
                      terapijski pristup. Dnevnik pijenja i mokrenja je samo dio
                      obrade noćnog mokrenja i ne može zamijeniti ostale nalaze
                      koje je potrebno učiniti u dijagnostici. Molim
                      konzultirajte se sa svojim liječnikom kako bi dijagnostika
                      noćnog mokrenja bila potpuna. Ispunjeni dnevnik moguće je
                      podijeliti sa svojim liječnikom. Dijeljenje dnevnika je
                      izričito na zahtjev korisnika i nije dopušteno
                      prosljeđivati izvještaj trećim osobama bez dopuštenja
                      korisnika.
                    </p>
                  </>
                }
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guides;
