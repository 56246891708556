const ConfirmSubmit = ({ submit, handleClose }) => {
  return (
    <div style={{ marginTop: "45%" }} className="px-2 outline-none">
      <div className="flex justify-center flex-col bg-white p-2 rounded">
        <div>
          <h5 className="font-semibold text-lg text-center my-2">
            Želite li završiti dnevnik? Vidjet ćete ga kao arhivirani dnevnik.
          </h5>
          <h5 className="text-md text-center my-2">
            Nakon potvrde otvorit će se arhivirani dnevnik.
          </h5>
        </div>
        <div className="px-2 mt-4">
          <div>
            <button
              className="btn btn-primary w-full text-center mx-auto"
              onClick={submit}
            >
              Završi
            </button>
            <button
              className="btn btn-danger mt-4 w-full text-center mx-auto"
              onClick={handleClose}
            >
              Odustani
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSubmit;
