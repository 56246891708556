import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import store from "store2";
import logo from "../../assets/images/logo.png";
import { Profiles, Journals, Bell } from "../../assets/images/svgs";

const Home = () => {
  let history = useHistory();
  const { data: activeJournal } = useQuery("activeJournal", async () => {
    const res = await store.get("activeJournal");
    if (res?.hasOwnProperty("id")) {
      return res;
    } else return false;
  });

  return (
    <div
      className="px-4 flex flex-col max-w-lg w-full mx-auto"
      style={{ minHeight: window.innerHeight }}
    >
      <div className="max-w-xs w-full mx-auto mt-10 mb-2">
        <div className="block w-full mx-auto">
          <img className="mx-auto" src={logo} alt="brand logo" />
        </div>
        <div className="flex justify-between">
          <div className="flex w-1/">
            <h2 className="font-semibold text-3xl my-4 text-darkT-500">Bok,</h2>
          </div>
          {activeJournal ? (
            <button
              className="inline-flex"
              onClick={() => history.push("/aktivni-dnevnik")}
            >
              <div className="inline-flex self-center text-gray-700 relative">
                <Bell />
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span
                    className="absolute animate-ping inline-flex w-3 h-3 rounded-full bg-emerald-400"
                    style={{ opacity: 0.75 }}
                  ></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-500"></span>
                </span>
              </div>
            </button>
          ) : null}
        </div>
        <h5 className="text-justify text-s text-darkT-200">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
        </h5>
      </div>
      <div className="w-full mx-auto flex flex-wrap mt-5">
        <Link to="/profili" className="w-full">
          <div className="card-sm w-full flex my-2">
            <div className="w-3/12 flex items-center">
              <div className="w-16">
                <Profiles />
              </div>
            </div>
            <div className="w-9/12">
              <h4 className="font-semibold text-2xl text-darkT-500">Profili</h4>
              <h5 className="text-base text-darkT-200">
                Pregled i izrada profila
              </h5>
            </div>
          </div>
        </Link>
        <Link to="/dnevnici" className="w-full">
          <div className="card-sm w-full flex my-2">
            <div className="w-3/12 flex items-center">
              <div className="w-16">
                <Journals />
              </div>
            </div>
            <div className="w-9/12">
              <h4 className="font-semibold text-2xl text-darkT-500">
                Dnevnici
              </h4>
              <h5 className="text-base text-darkT-200">
                Izrada novog i pregled arhiviranih dnevnika
              </h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="flex mt-auto mb-0 justify-around pt-2 pb-6">
        <div>
          <Link
            className="text-sky-600 text-lg hover:underline active:underline"
            to="/upute"
          >
            Upute
          </Link>
        </div>
        <div>
          <Link
            className="text-sky-600 text-lg hover:underline active:underline"
            to="/o-aplikaciji"
          >
            O aplikaciji
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
