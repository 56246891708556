import { Modal } from "@material-ui/core";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import store from "store2";
import { Close } from "../../assets/images/svgs";
import CustomSelect from "../../components/CustomSelect";
import Datepicker from "../../components/DatePicker";
import NewProfile from "../Profiles/NewProfile";

const NewJournal = () => {
  let history = useHistory();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { datumPocetka: dayjs() } });

  const [loading, setLoading] = useState(false);

  const [profileModal, setProfileModal] = useState(false);

  const { data, isFetching, refetch } = useQuery(
    "profiles",
    async () => {
      const res = await store
        .get("profiles")
        ?.map((item) => ({ label: item.ime, value: item }));
      console.log(res);
      if (res) {
        return [
          ...res,
          {
            label: (
              <button type="button" className="btn btn-primary w-full">
                Napravi novi profil
              </button>
            ),
            value: "create",
          },
        ];
      } else return [];
    },
    {}
  );

  const submit = async (data) => {
    setLoading(true);
    const id = nanoid();
    await store("activeJournal", {
      ...data,
      id,
      day: 1,
      mokrenjaDan: [],
      mokrenjaNoc: [],
      pijenja: [],
      nezabiljezenaMokrenja: [],
      allMornings: [],
      allSleeping: [],
      allNezabiljezena: [],
    });
    await store.add("journals", [{ ...data, id, active: true }]);

    history.push("/aktivni-dnevnik");
  };

  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">
        Novi dnevnik
      </h2>

      <div className="w-full mx-auto flex flex-wrap mt-6">
        <form className="w-full px-2" onSubmit={handleSubmit(submit)}>
          {isFetching ? (
            <div>Učitavanje</div>
          ) : data.length === 0 ? (
            <button
              onClick={() => setProfileModal(true)}
              type="button"
              className="btn btn-primary w-full"
            >
              Napravi novi profil
            </button>
          ) : (
            <div className="mb-4 w-full">
              <label
                className="block text-gray-700 text-md font-bold mb-2"
                htmlFor="profil"
              >
                Izaberi profil
              </label>
              <Controller
                name="profil"
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    onChange={(e) => {
                      if (e.value === "create") {
                        setProfileModal(true);
                      } else {
                        field.onChange(e);
                      }
                    }}
                    options={data}
                  />
                )}
                control={control}
                rules={{ required: "Odaberi profil" }}
              />
              {errors.profil && (
                <span className="error">{errors.profil.message}</span>
              )}
            </div>
          )}
          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              htmlFor="datumPocetka"
            >
              Datum početka
            </label>
            <Controller
              name="datumPocetka"
              render={({ field }) => (
                <Datepicker
                  {...field}
                  format="DD.MM.YYYY"
                  autoOk
                  error={errors.datumPocetka}
                />
              )}
              control={control}
              rules={{ required: "Unesi datum početka" }}
            />
            {errors.datumPocetka && (
              <span className="error">{errors.datumPocetka.message}</span>
            )}
          </div>

          <div className="mb-4 w-full">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              htmlFor="brojDana"
            >
              Broj dana
            </label>
            <Controller
              name="brojDana"
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  maxLength={2}
                  className="input"
                  decimalSeparator={false}
                  inputMode="numeric"
                  type="text"
                  placeholder="Broj dana"
                  error={errors.brojDana}
                />
              )}
              control={control}
              rules={{ required: "Unesi broj dana" }}
            />
            {errors.brojDana && (
              <span className="error">{errors.brojDana.message}</span>
            )}
          </div>
        </form>
        <div className="mb-4 w-full">
          <div>
            <p className="text-justify px-4">
              Dnevnik objektivizira broj mokrenja tijekom dana, kapacitet
              mjehura, noćnu proizvodnju mokraće te volumen i raspodjelu dnevnog
              unosa tekućine. Ispunjava se od strane ispitanika ili
              roditelja/staratelja. Izuzev što je potrebno zabilježiti volumen i
              vrijeme svakog pojedinog mokrenja i pijenja, potrebno je i
              probuditi ispitanika nakon što je zaspao (najčešće u 2 navrata 1h
              i 4h od odlaska na spavanje) te izmjeriti volumen mokrenja s
              ciljem mjerenja noćne proizvodnje mokraće. Umjesto buđenja noću
              može se izmjeriti težina mokre pelene.{" "}
            </p>
            <p className="text-justify px-4">
              Za ovu pretragu vam je potrebna mjerna posuda.
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-auto ">
        <div className="my-4 w-full">
          <button
            disabled={loading}
            onClick={handleSubmit(submit)}
            className="btn btn-primary w-full text-center mx-auto"
          >
            Započni
          </button>
          <button
            disabled={loading}
            onClick={() => history.push("/dnevnici")}
            className="btn btn-secondary w-full text-center mx-auto mt-4"
          >
            Odustani
          </button>
        </div>
        {profileModal ? (
          <Modal open={profileModal}>
            <div className="bg-white h-full px-2">
              <div className="flex self-end">
                <div className="p-2 ml-auto mr-0">
                  <Close
                    className="cursor-pointer w-7 h-7"
                    onClick={() => setProfileModal(false)}
                  />
                </div>
              </div>
              <NewProfile
                from="newJournal"
                close={() => setProfileModal(false)}
                setProfile={setValue}
                refetch={refetch}
              />
            </div>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default NewJournal;
