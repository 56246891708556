import { useMemo } from "react";
import dayjs from "dayjs";
import { Close } from "../../assets/images/svgs";
import Table from "../../components/Table";

const Preview = ({ data, setPreview }) => {
  const { res } = data;
  console.log(res);
  const assembleData = () => {
    let array = [];
    res.mokrenjaDan.map((item) => {
      array.push({ ...item, mokrenje: item.volumen });
      return null;
    });
    res.mokrenjaNoc.map((item) => {
      array.push({ ...item, mokrenje: item.volumen });
      return null;
    });
    res.pijenja.map((item) => {
      array.push({ ...item, pijenje: item.volumen });
      return null;
    });
    res.allMornings.map((item) => {
      array.push({ ...item, mokrenje: item.volumen });
      return null;
    });
    res.allSleeping.map((item) => {
      array.push({ ...item, mokrenje: "Spavanje" });
      return null;
    });
    const sorted = array.sort(
      (a, b) =>
        dayjs(a.vrijeme).valueOf() - dayjs(b.vrijeme).valueOf() || b.day - a.day
    );

    return sorted;
  };

  const cols = useMemo(
    () => [
      { Header: "Dan", accessor: "day" },
      {
        Header: "Vrijeme",
        accessor: "vrijeme",
        Cell: (props) => dayjs(props.value).format("HH:mm"),
      },
      { Header: "Mokrenje", accessor: "mokrenje" },
      { Header: "Pijenje", accessor: "pijenje" },
    ],
    []
  );

  return (
    <div className="bg-white w-full h-full overflow-auto">
      <div className="flex self-end">
        <div className="p-2 ml-auto mr-0">
          <Close
            className="cursor-pointer w-7 h-7"
            onClick={() => setPreview(false)}
          />
        </div>
      </div>
      <h5 className="font-semibold text-lg text-gray-700 text-center mb-4">
        Pregled mokrenja i pijenja
      </h5>
      <Table columns={cols} data={assembleData()} />
    </div>
  );
};

export default Preview;
