import { useState } from "react";
import { Modal } from "@material-ui/core";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { Redirect, useHistory } from "react-router-dom";
import store from "store2";
import AddEntry from "./AddEntry";
import Alert from "../../components/Alert";
import Preview from "./Preview";
import ConfirmSubmit from "./ConfimSubmit";
import { generateArchived } from "../../helpers/functions";

const ActiveJournal = () => {
  let history = useHistory();

  const [addEntry, setAddEntry] = useState({ state: false, type: "" });

  const [preview, setPreview] = useState(false);

  const [error, setError] = useState({ state: false, message: "" });

  const [confirmSubmit, setConfirmSubmit] = useState({ state: false });

  const { data, isFetching, refetch } = useQuery(
    "activeJournal",
    async () => {
      const res = await store.get("activeJournal");
      console.log(res);
      const firstMorning = res[`prvoMokrenje${res.day}`];
      const dayMokrenja = res.mokrenjaDan.filter(
        (item) => item.day === res.day
      );
      const mokrenjaNoc = res.mokrenjaNoc.filter(
        (item) => item.day === res.day
      );
      const dayPijenja = res.pijenja.filter((item) => item.day === res.day);
      const nezabiljezenaMokrenja = res[`nezabiljezenaMokrenja${res.day}`];
      const spavanje = res[`spavanje${res.day}`];

      const canContinue = () => {
        if (
          firstMorning &&
          dayMokrenja.length > 0 &&
          mokrenjaNoc.length > 0 &&
          dayPijenja &&
          nezabiljezenaMokrenja &&
          spavanje
        )
          return true;
      };

      return {
        res,
        firstMorning,
        dayMokrenja: dayMokrenja.length + (firstMorning?.day ? 1 : 0),
        dayPijenja: dayPijenja.length,
        mokrenjaNoc: mokrenjaNoc.length,
        nezabiljezenaMokrenja,
        spavanje,
        canContinue: canContinue(),
      };
    },
    {}
  );

  const handleAddEntry = (type) => {
    setAddEntry({ state: true, type });
  };

  const advanceDay = async () => {
    const res = await store.get("activeJournal");
    res.day = res.day + 1;
    await store("activeJournal", res);
    refetch();
    window.scrollTo(0, 0);
  };
  const backOneDay = async () => {
    const res = await store.get("activeJournal");
    res.day = res.day - 1;
    await store("activeJournal", res);
    refetch();
    window.scrollTo(0, 0);
  };

  const cannotSubmit = () => {
    setError({
      state: true,
      message: `Ispuni sve podatke da bi ${
        data.res.day === Number(data.res.brojDana)
          ? "završio dnevnik"
          : "išao u sljedeći dan"
      }`,
    });
    setTimeout(() => {
      setError({ state: false, message: "" });
    }, 3000);
  };

  const handleConfirmSubmit = () => {
    setConfirmSubmit({ state: true });
  };

  const submit = async (data) => {
    const activeJournal = await store.get("activeJournal");
    const allJournals = await store.get("journals");
    console.log(activeJournal);
    const archivedJournal = generateArchived(activeJournal);
    console.log(archivedJournal);
    await store(
      "journals",
      allJournals.filter((item) => item.id !== activeJournal.id)
    );
    await store.add("journals", [{ ...archivedJournal, active: false }]);
    await store("activeJournal", {});

    await store("archivedJournal", archivedJournal);
    history.push("/dnevnici");
  };

  if (!isFetching && !data) {
    return <Redirect to="/dnevnici" />;
  }

  return (
    <>
      {isFetching ? (
        <div className="max-w-xs w-full mx-auto mt-10 mb-2">loading</div>
      ) : (
        <>
          <h2 className="font-semibold text-3xl my-4 text-darkT-500">
            Aktivni dnevnik
          </h2>
          <div className="px-2 py-1 rounded bg-indigo-600 flex gap-3">
            <h5 className="w-1/3 text-white mx-auto text-center">
              {data.res.profil.label}
            </h5>
            <h5 className="w-1/3 text-white mx-auto text-center">
              {dayjs(data.res.datumPocetka).format("DD.MM.YYYY")}
            </h5>
            <h5 className="w-1/3 text-white mx-auto text-center">
              Dan {data.res.day}
            </h5>
          </div>
          <div className="w-full mx-auto flex flex-wrap mt-6">
            <div className="w-full px-2">
              <div className="my-4 mx-auto flex">
                <div className=" relative inline-flex">
                  <button
                    className="btn bg-emerald-700 ring-emerald-500 mx-auto relative"
                    onClick={() => handleAddEntry("prvoMokrenje")}
                  >
                    Dodaj prvo jutarnje mokrenje
                  </button>
                  {data.firstMorning ? (
                    <span className="flex absolute h-6 w-6 top-0 right-0 -mt-2 -mr-1 text-yellow-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 inline-flex"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  ) : null}
                </div>
              </div>
              <hr></hr>
              <div className="my-4 w-full mx-auto flex gap-4">
                <button
                  className="btn bg-emerald-700 ring-emerald-500 mx-auto w-1/2"
                  onClick={() => handleAddEntry("mokrenjaDan")}
                >
                  Dodaj mokrenje
                </button>
                <div className="w-1/2 self-center">
                  <p className="text-center self-center">
                    Mokrenja: {data.dayMokrenja}
                  </p>
                </div>
              </div>
              <hr></hr>
              <div className="my-4 w-full mx-auto flex gap-4">
                <button
                  className="btn bg-emerald-700 ring-emerald-500 mx-auto w-1/2"
                  onClick={() => handleAddEntry("pijenja")}
                >
                  Dodaj pijenje
                </button>
                <div className="w-1/2 self-center">
                  <p className="text-center self-center">
                    Pijenja: {data.dayPijenja}
                  </p>
                </div>
              </div>
              <hr></hr>

              <div className="my-4 w-full mx-auto flex">
                <button
                  className="btn bg-gray-700 ring-gray-400 mx-auto"
                  onClick={() => setPreview(true)}
                >
                  Pregled mokrenja i pijenja
                </button>
              </div>
              <hr></hr>

              <div className="my-4 w-full mx-auto flex gap-4">
                <p className="text-justify">
                  Odabrati vrijeme odlaska na spavanje. Tijekom sna potrebno se
                  probuditi (najčešće 1 sat i 4 sata od usnivanja) i zabilježiti
                  vrijeme i volumen mokrenja. Mokrenje u pelene je potrebno
                  izvagati i vrijednost u gramima zabilježiti pod jedno mokrenje
                  (oduzeti težinu suhe pelene). Možete postaviti alarm za
                  buđenje.
                </p>
              </div>
              <hr></hr>
              <div className="my-4 w-full mx-auto flex gap-4">
                <button
                  className="btn bg-emerald-700 ring-emerald-500 mx-auto w-3/4"
                  onClick={() => handleAddEntry("spavanje")}
                >
                  Odaberi odlazak na spavanje
                </button>
                <div className="w-1/4 self-center">
                  <p className="text-center self-center">
                    {data.spavanje && dayjs(data.spavanje).format("HH:mm")}
                  </p>
                </div>
              </div>
              <hr></hr>
              {data.spavanje && dayjs(data.spavanje).isValid() ? (
                <>
                  <div className="my-4 w-full mx-auto flex gap-4">
                    <button
                      className="btn bg-emerald-700 ring-emerald-500 mx-auto w-2/3"
                      onClick={() => handleAddEntry("mokrenjaNoc")}
                    >
                      Dodaj mokrenje u noći
                    </button>
                    <div className="w-1/3 self-center">
                      <p className="text-center self-center">
                        Mokrenja: {data.mokrenjaNoc}
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="my-4 w-full mx-auto flex gap-4 flex-wrap">
                    <button
                      className="btn bg-emerald-700 ring-emerald-500 mx-auto w-full"
                      onClick={() => handleAddEntry("nezabiljezenaMokrenja")}
                    >
                      Nezabilježena mokrenja u noći
                    </button>
                    <div className="w-full self-center">
                      {data?.nezabiljezenaMokrenja?.map((item) => (
                        <p className="text-center self-center">{item.value}</p>
                      ))}
                    </div>
                  </div>
                  <hr></hr>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex mt-auto flex-wrap">
            {error.state ? (
              <div className="my-2 w-full">
                <Alert text={error.message} type="danger" noClose noMb />
              </div>
            ) : null}
            <div className="my-4 w-full">
              <button
                onClick={
                  data.res.day === Number(data.res.brojDana)
                    ? data.canContinue
                      ? handleConfirmSubmit
                      : cannotSubmit
                    : data.canContinue
                    ? advanceDay
                    : cannotSubmit
                }
                className="btn btn-primary w-full text-center mx-auto"
              >
                {data.res.day === Number(data.res.brojDana)
                  ? "Završi dnevnik"
                  : "Sljedeći dan"}
              </button>
              <button
                disabled={data.res.day === 1}
                onClick={backOneDay}
                className="btn btn-secondary w-full text-center mx-auto mt-4"
              >
                Prošli dan
              </button>
            </div>
          </div>
        </>
      )}
      {confirmSubmit.state ? (
        <Modal open={confirmSubmit.state}>
          <ConfirmSubmit
            submit={submit}
            handleClose={() => setConfirmSubmit({ state: false })}
          />
        </Modal>
      ) : null}
      {addEntry.state ? (
        <Modal open={addEntry.state}>
          <AddEntry
            day={data.res.day}
            addEntry={addEntry}
            setAddEntry={setAddEntry}
          />
        </Modal>
      ) : null}
      {preview ? (
        <Modal open={preview}>
          <Preview data={data} setPreview={setPreview} />
        </Modal>
      ) : null}
    </>
  );
};

export default ActiveJournal;
