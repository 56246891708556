import { useState } from "react";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import store from "store2";
import TimePicker from "../../components/Timepicker";
import { useQueryClient } from "react-query";
import CustomSelect from "../../components/CustomSelect";

const types = {
  prvoMokrenje: "Dodaj prvo jutarnje mokrenje",
  mokrenjaDan: "Dodaj mokrenje",
  pijenja: "Dodaj pijenje",
  spavanje: "Odlazak na spavanje",
  nezabiljezenaMokrenja:
    "Je li tijekom noći bilo mokrenja izvan zabilježenih mjerenja? Odabrati.",
};

const AddEntry = ({ day, addEntry, setAddEntry }) => {
  const { type } = addEntry;
  const queryClient = useQueryClient();
  const [loading] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { vrijeme: dayjs() } });

  const submit = (data) => {
    if (type === "mokrenjaNoc" && dayjs(data.vrijeme).hour() < 7) {
      Object.assign(data, { vrijeme: dayjs(data.vrijeme).add(1, "day") });
    }
    Object.assign(data, { vrijeme: dayjs(data.vrijeme).add(day - 1, "day") });
    const obj = { ...data, day };
    const activeJournal = Object.assign({}, store.get("activeJournal"));
    if (type === "prvoMokrenje") {
      const addToJournal = Object.assign(activeJournal, { [type + day]: obj });
      addToJournal.allMornings.push(obj);
      store("activeJournal", addToJournal);
      queryClient.invalidateQueries("activeJournal");
      setAddEntry({ state: false, type: "" });
    } else if (type === "spavanje") {
      activeJournal[type + day] = data.vrijeme;
      activeJournal.allSleeping.push({ day, vrijeme: data.vrijeme });
      store("activeJournal", activeJournal);
      queryClient.invalidateQueries("activeJournal");
      setAddEntry({ state: false, type: "" });
    } else if (type === "nezabiljezenaMokrenja") {
      activeJournal[type + day] = data.nezabiljezenaMokrenja;
      activeJournal.allNezabiljezena.push({
        day,
        nezabiljezenaMokrenja: data.nezabiljezenaMokrenja.map(
          (item) => item.value
        ),
      });
      store("activeJournal", activeJournal);
      queryClient.invalidateQueries("activeJournal");
      setAddEntry({ state: false, type: "" });
    } else {
      activeJournal[type].push(obj);
      store("activeJournal", activeJournal);
      queryClient.invalidateQueries("activeJournal");
      setAddEntry({ state: false, type: "" });
    }
  };

  return (
    <div style={{ marginTop: "25%" }} className="px-2 outline-none">
      <div className="flex justify-center flex-col bg-white p-2 rounded">
        <div>
          <h5 className="font-semibold text-lg text-center my-2">
            {types[type]}
          </h5>
        </div>
        <form className="px-2 mt-2">
          {type === "nezabiljezenaMokrenja" ? (
            <div className="mb-4 w-full">
              <label
                className="block text-gray-700 text-md font-bold mb-2"
                for="nezabiljezenaMokrenja"
              >
                Nezabilježena mokrenja
              </label>
              <Controller
                name="nezabiljezenaMokrenja"
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    placeholder={"Odaberi "}
                    isMulti
                    options={[
                      { label: "Ne", value: "Ne" },
                      {
                        label: "Bilo je mokrenje u krevet",
                        value: "Bilo je mokrenje u krevet",
                      },
                      {
                        label: "Sam je mokrio u WC",
                        value: "Sam je mokrio u WC",
                      },
                    ]}
                    onChange={(e) => {
                      console.log(e);
                      field.onChange(e);
                    }}
                  />
                )}
                control={control}
                defaultValue={[]}
                rules={{ required: "Odaberi " }}
              />
              {errors.nezabiljezenaMokrenja && (
                <span className="error">
                  {errors.nezabiljezenaMokrenja.message}
                </span>
              )}
            </div>
          ) : (
            <>
              <div className="mb-4 w-full">
                <label
                  className="block text-gray-700 text-md font-bold mb-2"
                  for="vrijeme"
                >
                  Vrijeme
                </label>
                <Controller
                  name="vrijeme"
                  render={({ field }) => (
                    <TimePicker
                      {...field}
                      inputMode="decimal"
                      format="HH:mm"
                      autoOk
                      error={errors.vrijeme}
                    />
                  )}
                  control={control}
                  rules={{ required: "Unesi vrijeme" }}
                />
                {errors.vrijeme && (
                  <span className="error">{errors.vrijeme.message}</span>
                )}
              </div>
              {type === "spavanje" ? null : (
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-md font-bold mb-2"
                    for="volumen"
                  >
                    Volumen
                  </label>
                  <div className="relative">
                    <Controller
                      name="volumen"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className="input"
                          decimalSeparator={","}
                          type="text"
                          placeholder="Volumen"
                          error={errors.volumen}
                          inputMode="decimal"
                        />
                      )}
                      control={control}
                      rules={{ required: "Unesi volumen" }}
                    />
                    <span className=" absolute right-0 bottom-0 top-0 text-lg p-4 text-black dark:text-white">
                      mL
                    </span>
                  </div>
                  {errors.volumen && (
                    <span className="error">{errors.volumen.message}</span>
                  )}
                </div>
              )}
            </>
          )}
          <div className="flex mt-auto ">
            <div className="my-4 w-full flex gap-4">
              <button
                disabled={loading}
                onClick={() =>
                  setAddEntry((addEntry) => ({ ...addEntry, state: false }))
                }
                className="btn btn-secondary w-full text-center mx-auto"
              >
                Odustani
              </button>
              <button
                disabled={loading}
                onClick={handleSubmit(submit)}
                className="btn btn-success w-full text-center mx-auto"
              >
                Dodaj
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEntry;
