import { Route, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import store from "store2";
import { BackArrow, Bell } from "../assets/images/svgs";

const AppLayout = ({ children, backTo, history }) => {
  const { data: activeJournal } = useQuery(
    ["activeJournalExists", history],
    async () => {
      const res = await store.get("activeJournal");
      if (res?.hasOwnProperty("id")) {
        return res;
      } else return false;
    }
  );
  console.log(activeJournal);
  return (
    <div
      className="px-4 flex flex-col select-none max-w-lg w-full mx-auto"
      style={{ minHeight: window.innerHeight }}
    >
      <div className="w-full mx-auto mt-8 mb-2 flex justify-between">
        <div className="inline-block">
          <button
            onClick={() => {
              if (backTo) {
                history.push(backTo);
              } else history.goBack();
            }}
          >
            <BackArrow />
          </button>
        </div>
        {activeJournal ? (
          <button
            className="inline-flex mr-2"
            onClick={() => history.push("/aktivni-dnevnik")}
          >
            <div className="inline-flex self-center text-gray-700 relative">
              <Bell />
              <span className="flex absolute  h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span
                  className="absolute animate-ping inline-flex w-3 h-3 rounded-full bg-emerald-400"
                  style={{ opacity: 0.75 }}
                ></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-500"></span>
              </span>
            </div>
          </button>
        ) : null}
      </div>
      {children}
    </div>
  );
};

const LayoutRoute = ({ children, backTo, ...rest }) => {
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => (
        <AppLayout backTo={backTo} history={history} {...props}>
          {children}
        </AppLayout>
      )}
    />
  );
};

export default LayoutRoute;
