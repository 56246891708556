const Wraper = ({ color, icon, text, onClose, noMb, noClose, children }) => {
  const icons = () => ({
    success: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    info: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
    ),
    danger: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        />
      </svg>
    ),
  });
  return (
    <div
      className={`relative flex content-center justify-between items-center w-100 h-12 ${
        noMb ? "" : "mb-10"
      } rounded-xl border-2 border-solid border-${color}-500 bg-${color}-200 text-${color}-500 px-2`}
      role="alert"
    >
      {icons()[icon]}
      <p className={`font-semibold text-center ${noClose ? "w-full" : ""}`}>
        {children ? children : text}
      </p>
      <button
        className={`close ${noClose ? "hidden" : ""}`}
        data-dismiss="alert"
        type="button"
        onClick={onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
const Alert = ({ text, onClose, icon, type, noMb, noClose, children }) => {
  const types = () => ({
    danger: (
      <Wraper
        color="red"
        onClose={onClose}
        icon={icon}
        text={text}
        noMb={noMb}
        noClose={noClose}
      >
        {children && children}
      </Wraper>
    ),
    success: (
      <Wraper
        color="alertGreen"
        onClose={onClose}
        icon={icon}
        text={text}
        noMb={noMb}
        noClose={noClose}
      >
        {children && children}
      </Wraper>
    ),
    info: (
      <Wraper
        color="gray"
        onClose={onClose}
        icon={icon}
        text={text}
        noMb={noMb}
        noClose={noClose}
      >
        {children && children}
      </Wraper>
    ),
  });
  return types()[type];
};

export default Alert;
