import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { MainBig } from "../../assets/images/svgs";
import arrow_forward from "../../assets/images/arrow_forward.png";
import AddToHome from "../../components/AddToHome/AddToHome";

const Main = () => {
  return (
    <div className="max-w-lg w-full mx-auto">
      <div className="max-w-xs w-full mx-auto mt-10 mb-2">
        <div className="block w-full mx-auto">
          <img className="mx-auto" src={logo} alt="brand logo" />
        </div>
        <h2 className="text-center font-semibold text-3xl my-4 text-darkT-500">
          BeDry
        </h2>
        <h5 className="text-center text-s text-darkT-200">
          Lorem ipsum text
        </h5>
      </div>
      <div className="max-w-255 flex items-center mx-auto mt-11">
        <MainBig width="200px" className="mx-auto" />
      </div>
      <div className="mt-20 max-w-xs w-full mx-auto">
        <Link to="/home">
          <button className="btn btn-primary w-full px-6 py-4 relative font-semibold text-lg shadow-md">
            <img src={arrow_forward} className="absolute" alt="profile" />
            Kreni
          </button>
        </Link>
      </div>
      {/* <div className="w-full mx-auto flex">
        <h5 className="mx-auto font-md text-blueT-400 text-center my-7 cursor-pointer hover:underline">
          O aplikaciji
        </h5>
      </div> */}
      <AddToHome />
    </div>
  );
};

export default Main;
