import classNames from "classnames";
import { useCallback, useRef } from "react";

const Accordion = ({ id, pickedID, setPicked, title, text }) => {
  const tab = useRef();
  const handleOpen = () => {
    if (id === pickedID) {
      setPicked(0);
    } else {
      setPicked(id);
    }
  };
  const handleToggle = useCallback(() => {
    if (id === pickedID) {
      return { maxHeight: `${tab.current.scrollHeight}px` };
    } else return { maxHeight: 0 };
  }, [id, pickedID]);

  return (
    <li className="bg-white my-2 shadow-md rounded-md">
      <h2
        onClick={handleOpen}
        className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer"
      >
        <span>{title}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classNames({
            [` text-sky-700 h-6 w-6 transform transition-transform duration-300`]: true,
            [`rotate-180`]: id === pickedID,
          })}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
          />
        </svg>
      </h2>
      <div
        x-ref="tab"
        ref={tab}
        style={handleToggle()}
        className="border-l-2 rounded-b border-sky-600 overflow-hidden duration-300 transition-all"
      >
        {text}
      </div>
    </li>
  );
};

export default Accordion;
