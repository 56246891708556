import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "@material-ui/core";
import store from "store2";

const About = () => {
  let history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteAll = () => {
    store.clearAll();
    history.push("/");
  };

  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">
        O aplikaciji
      </h2>

      <div>
        <h5 className="text-md text-gray-800 text-justify my-2">
          BeDry je namijenjen pacijentima s noćnim mokrenjem, roditeljima djece
          s noćnim mokrenjem i liječnicima kojima je potreban dnevnik pijenja i
          mokrenja u dijagnostici noćnog mokrenja. Aplikacija je razvijena kao
          dnevnik kojim se objektiviziraju navike pijenja i mokrenja i
          izračunava stvarni kapacitet mjehura i volumen noćne proizvodnje
          mokraće.
        </h5>
        <h5 className="text-md text-gray-800 text-justify mt-4 mb-2">
          Podaci koje korisnik unese pohranjeni su isključivo lokalno, na
          korisnikovom mobitelu. Dijeljenje dnevnika s drugim osobama moguće je
          samo preko zahtjeva korisnika unutar aplikacije. Ime profila ne mora
          odgovarati imenu korisnika. Kod dijeljenja dnevnika dostupna je samo
          dob profila u godinama ali ne i datum rođenja. Autor, naručitelj i
          developer ne mogu snositi odgovornost za eventualnu štetu nastalu
          dijeljenjem dnevnika s trećim osobama.
        </h5>
      </div>
      <div className="w-full mx-auto flex flex-wrap mt-4">
        <div className="w-full">
          <h5 className="text-lg text-gray-800 text-justify my-1">Autor:</h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            Slaven Abdović
          </h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            LinkedIn:{" "}
            <a
              className="text-blue-600 active:underline"
              href="https://www.linkedin.com/in/slaven-abdovic/"
            >
              Link
            </a>
          </h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            Email:{" "}
            <a
              className="text-blue-600 active:underline"
              href="mailto:sabdovic@mefhr.org"
            >
              sabdovic@mefhr.org
            </a>
          </h5>
        </div>
        <div className="w-full mt-2">
          <h5 className="text-lg text-gray-800 text-justify my-1">
            Suradnici:
          </h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            Antonella Geljić
          </h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            Mirjana Stanić{" "}
          </h5>
        </div>
        <div className="w-full mt-2">
          <h5 className="text-lg text-gray-800 text-justify my-1">Zahvala:</h5>
          <h5 className="text-md text-gray-800 text-justify my-1">
            Elizabeta Velić
          </h5>
        </div>
        <div className="w-full mt-2">
          <h5 className="text-lg text-gray-800 text-justify my-1">
            Developer:
          </h5>
          <h5 className="text-md text-gray-800 text-justify my-1">Parpar</h5>
        </div>
      </div>
      <div className="flex mt-auto mb-0">
        <div className="my-4 w-full">
          {" "}
          <h5 className="text-md text-gray-800 font-semibold text-justify my-2">
            Sve podatke unutar aplikacije, uključujući sve izrađene profile, sve
            unesene dnevnike i generirane izvještaje moguće je obrisati preko
            sljedeće naredbe:
          </h5>
          <div>
            <button
              className="btn btn-danger w-full"
              onClick={() => setConfirmDelete(true)}
            >
              OBRIŠI SVE
            </button>
          </div>
        </div>
        {confirmDelete ? (
          <div>
            <Modal open={confirmDelete}>
              <div style={{ marginTop: "45%" }} className="px-2 outline-none">
                <div className="flex justify-center flex-col bg-white p-2 rounded">
                  <div>
                    <h5 className="font-semibold text-lg text-center my-2">
                      Želite li obrisati sve podatke unutar aplikacije?
                    </h5>
                  </div>
                  <div className="px-2 mt-4">
                    <div>
                      <button
                        className="btn btn-danger w-full text-center mx-auto"
                        onClick={deleteAll}
                      >
                        Potvrdi brisanje
                      </button>
                      <button
                        className="btn btn-secondary mt-4 w-full text-center mx-auto"
                        onClick={() => setConfirmDelete(false)}
                      >
                        Odustani
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default About;
