import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Main from "../Main/Main";
import NoMatch from "../../components/NoMatch";
import Home from "../Home/Home";
import Profiles from "../Profiles/Profiles";
import NewProfile from "../Profiles/NewProfile";
import Journals from "../Journals/Journals";
import NewJournal from "../Journals/NewJournal";
import ActiveJournal from "../Journals/ActiveJournal";
import LayoutRoute from "../../components/LayoutRoute";
import ArchivedJournal from "../Journals/ArchivedJournal";
import About from "../App/About";
import Guides from "./Guides";
import ScrollToTop from "../../helpers/hooks";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <LayoutRoute backTo="/home" exact path="/profili">
              <Profiles />
            </LayoutRoute>
            <LayoutRoute exact path="/novi-profil">
              <NewProfile />
            </LayoutRoute>
            <LayoutRoute backTo="/home" exact path="/dnevnici">
              <Journals />
            </LayoutRoute>
            <LayoutRoute exact path="/novi-dnevnik">
              <NewJournal />
            </LayoutRoute>
            <LayoutRoute backTo="/dnevnici" exact path="/aktivni-dnevnik">
              <ActiveJournal />
            </LayoutRoute>
            <LayoutRoute exact path="/arhivirani-dnevnik">
              <ArchivedJournal />
            </LayoutRoute>
            <LayoutRoute exact path="/o-aplikaciji">
              <About />
            </LayoutRoute>
            <LayoutRoute exact path="/upute">
              <Guides />
            </LayoutRoute>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </QueryClientProvider>
      </div>
    </Router>
  );
}

export default App;
