import dayjs from "dayjs";

export const generateArchived = (activeJournal) => {
  const archiveJournal = { days: [] };
  for (let i = 0; Number(activeJournal.brojDana) > i; i++) {
    const obj = {};
    const mokrenjaDan = activeJournal.mokrenjaDan.filter(
      (item) => item.day === i + 1
    );
    const mokrenjaNoc = activeJournal.mokrenjaNoc.filter(
      (item) => item.day === i + 1
    );
    const pijenja = activeJournal.pijenja.filter((item) => item.day === i + 1);
    const nezabiljezenaMokrenja = activeJournal.allNezabiljezena.filter(
      (item) => item.day === i + 1
    );
    const spavanje = activeJournal.allSleeping.filter(
      (item) => item.day === i + 1
    );

    const twoHBeforeSleep = dayjs(spavanje[0].vrijeme).subtract(2, "hours");
    const fluidBeforeSleep = pijenja
      .filter((item) => {
        if (
          dayjs(item.vrijeme).isAfter(dayjs(twoHBeforeSleep)) &&
          dayjs(item.vrijeme).isBefore(dayjs(spavanje[0].vrijeme))
        ) {
          return true;
        } else return false;
      })
      .reduce((a, b) => Number(a.volumen) + Number(b.volumen), {
        volumen: 0,
      });
    console.log(
      i + 1 < Number(activeJournal.brojDana),
      i + 1 < Number(activeJournal.brojDana) &&
        dayjs(activeJournal[`prvoMokrenje${i + 2}`].vrijeme)
    );
    const nightCapacity = [...mokrenjaNoc, ...mokrenjaDan].filter((item) => {
      if (i + 1 < Number(activeJournal.brojDana)) {
        if (
          dayjs(item.vrijeme).isAfter(dayjs(spavanje[0].vrijeme)) &&
          dayjs(item.vrijeme).isBefore(
            dayjs(activeJournal[`prvoMokrenje${i + 2}`].vrijeme)
          )
        ) {
          return true;
        } else return false;
      } else if (dayjs(item.vrijeme).isAfter(dayjs(spavanje[0].vrijeme))) {
        return true;
      } else return false;
    });
    const sumNight = nightCapacity.reduce(
      (a, b) => ({ volumen: Number(a.volumen) + Number(b.volumen) }),
      {
        volumen: 0,
      }
    );
    if (i + 1 < Number(activeJournal.brojDana)) {
      sumNight.volumen += Number(activeJournal[`prvoMokrenje${i + 2}`].volumen);
    }
    Object.assign(obj, {
      day: i + 1,
      mokrenjaDan: [...mokrenjaDan, activeJournal[`prvoMokrenje${i + 1}`]],
      mokrenjaNoc,
      pijenja,
      nezabiljezenaMokrenja: nezabiljezenaMokrenja[0],
      spavanje: spavanje[0],
      fluidBeforeSleep,
      unosTekucine: pijenja.reduce(
        (a, b) => ({ volumen: Number(a.volumen) + Number(b.volumen) }),
        { volumen: 0 }
      ),
      nightCapacity,
      sumNight,
    });
    archiveJournal.days.push(obj);
  }
  const actualCapacity = Math.max.apply(
    null,
    [...activeJournal.mokrenjaNoc, ...activeJournal.mokrenjaDan].map(
      (item) => item.volumen
    )
  );
  const expectedCapacity = activeJournal.profil.value.dob * 30 + 30;
  const expectedNightCapacity =
    (activeJournal.profil.value.dob * 30 + 30) * 1.3;

  Object.assign(archiveJournal, {
    ...activeJournal,
    expectedCapacity,
    actualCapacity,
    expectedNightCapacity,
  });
  console.log(archiveJournal);
  return archiveJournal;
};
