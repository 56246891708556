import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex">
      <label
        htmlFor="search"
        className="flex  mb-0 self-center content-end mr-2"
        style={{ fontSize: 16 }}
      >
        Pretraži:
      </label>
      <div className="">
        <input
          className="input h-9 ring-3"
          id="search"
          name="search"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} ${count < 5 ? "stavke" : "stavki"}...`}
          style={{
            fontSize: "1.1rem",
          }}
        />
      </div>
    </div>
  );
}
export default function DataTable({
  data,
  onOpen,
  setPickedValues,
  disablebuttons,
  disableSearch,
  columns,
  buttons,
  exportCols,
  exportData,
  name,
  pdfName,
  pdfCols,
  toolbar,
  showRows,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (showRows) {
      setPageSize(showRows);
    }
  }, [setPageSize, showRows]);

  return (
    <>
      <div className="flex mb-1 px-2 justify-between flex-wrap" id="tableUtils">
        {toolbar ? toolbar : null}
        <div
          className={classNames({
            [`flex mt-2 mt-lg-0 justify-center lg:justify-end align-items-center ml-auto`]: true,
            [`hidden`]: disableSearch ? true : false,
          })}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className="prices__container px-2 ">
        <table {...getTableProps()} className="custom-table print:table-fixed">
          <thead className="">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="prices__row_head"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`prices__cell text-center text-xs select-none ${
                      column.noPrint ? "noPrint" : ""
                    }`}
                  >
                    {column.render("Header")}
                    <span className="pl-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 inline-block"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 inline-block"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </>
                        )
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="prices__row"
                  style={{ cursor: "inherit" }}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={`prices__cell text-center ${
                        cell.column.noPrint ? "noPrint" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showRows ? null : (
        <div className=" mx-1 lg:mx-4 flex-wrap flex my-2 noPrint">
          <div className="flex mx-auto mb-4 mt-4">
            <div className="w-full">
              <button
                disabled={pageSize > data.length}
                className="btn btn-success mx-auto"
                onClick={() => setPageSize(Number(pageSize + 10))}
              >
                Učitaj još
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
