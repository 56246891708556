import { useState } from "react";
import { useQuery } from "react-query";
import classNames from "classnames";
import dayjs from "dayjs";
import store from "store2";
import { Modal } from "@material-ui/core";
import Preview from "./Preview";

const ArchivedJournal = () => {
  const { data, isFetching } = useQuery("archivedJournal", async () => {
    const res = await store.get("archivedJournal");
    return res;
  });
  const [preview, setPreview] = useState(false);
  return !isFetching ? (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">Izvješće</h2>
      <div className="flex flex-wrap">
        <div>
          <div className="bg-coolGray-200 mb-4 px-2 py-3 flex w-full flex-wrap rounded shadow-sm">
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Ime</div>
              <div className="flex ml-auto mr-0">
                <p>{data.profil.value.ime}</p>
              </div>
            </div>
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Dob</div>
              <div className="flex ml-auto mr-0">
                <p>{data.profil.value.dob}</p>
              </div>
            </div>
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Tjelesna masa</div>
              <div className="flex ml-auto mr-0">
                <p>{data.profil.value.masa} kg</p>
              </div>
            </div>
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Datum dnevnika</div>
              <div className="flex ml-auto mr-0">
                <p>{dayjs(data.datumPocetka).format("DD.MM.YYYY")}</p>
              </div>
            </div>
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Očekivani kapacitet mjehura</div>
              <div className="flex ml-auto mr-0">
                <p>{data.expectedCapacity}</p>
                <p className="ml-1">mL</p>
              </div>
            </div>
            <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Izmjereni kapacitet mjehura</div>
              <div
                className={classNames({
                  [`flex ml-auto mr-0`]: true,
                  [`text-red-600`]:
                    data.actualCapacity < data.expectedCapacity * 0.7,
                })}
              >
                <p>{data.actualCapacity}</p>
                <p className="ml-1">mL</p>
              </div>
            </div>
            {data.actualCapacity < data.expectedCapacity * 0.7 ? (
              <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-2">
                <div className="flex">
                  <p className="text-sm">
                    Maksimalni volumen mokrenja je za više od 30% manji od
                    očekivanog kapaciteta mokraćnog mjehura za dob.
                  </p>
                </div>
              </div>
            ) : null}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Broj dnevnih mokrenja</div>
            </div>
            {data.days.map((item) => {
              return (
                <div
                  className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6"
                  key={item.day}
                >
                  <div className="flex">Dan {item.day}.</div>
                  <div className="flex ml-auto mr-0 gap">
                    <p className="px-1">{item.mokrenjaDan.length}</p>
                  </div>
                </div>
              );
            })}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Noćna diureza</div>
            </div>
            {data.days.map((item) => {
              return (
                <>
                  <div
                    className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6"
                    key={item.day}
                  >
                    <div className="flex">Dan {item.day}.</div>
                    <div
                      className={classNames({
                        [`flex ml-auto mr-0 gap`]: true,
                        [`text-red-600`]:
                          item.sumNight.volumen > data.expectedNightCapacity
                            ? true
                            : false,
                      })}
                    >
                      <p className="px-1">{item.sumNight.volumen}</p>
                      <p className="ml-1">mL</p>
                    </div>
                  </div>
                  {item.sumNight.volumen > data.expectedNightCapacity ? (
                    <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-2">
                      <div className="flex">
                        <p className="text-sm">
                          Noćna poliurija (noćna proizvodnja mokraće premašuje
                          za &gt; 30% očekivani kapacitet mjehura)
                        </p>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Nezabilježena mokrenja</div>
            </div>
            {data.days.map((item) => {
              return (
                <div
                  className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6"
                  key={item.day}
                >
                  <div className="flex">Dan {item.day}.</div>
                  <div className="flex ml-auto mr-0 gap">
                    {
                      <div className="px-1">
                        {item.nezabiljezenaMokrenja.nezabiljezenaMokrenja.map(
                          (item) => (
                            <p key={item}>{item}</p>
                          )
                        )}
                      </div>
                    }
                  </div>
                </div>
              );
            })}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Dnevni unos tekućine</div>
            </div>
            {data.days.map((item) => {
              return (
                <div className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6" key={item.day}>
                  <div className="flex">Dan {item.day}.</div>
                  <div className="flex ml-auto mr-0 gap">
                    <p className="px-1">{item.unosTekucine.volumen}</p>
                    <p className="ml-1">mL</p>
                  </div>
                </div>
              );
            })}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Unos tekućine 2h pred spavanje</div>
            </div>
            {data.days.map((item) => {
              return (
                <div
                  className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6"
                  key={item.day}
                >
                  <div className="flex">Dan {item.day}.</div>
                  <div className="flex ml-auto mr-0 gap">
                    <p className="px-1">{item.fluidBeforeSleep.volumen}</p>
                    <p className="ml-1">mL</p>
                  </div>
                </div>
              );
            })}
            <div className="w-full flex border-t-2 border-solid border-gray-400 py-1">
              <div className="flex">Odlazak na spavanje</div>
            </div>
            {data.days.map((item) => {
              return (
                <div
                  className="w-full flex justify-end border-t-2 border-solid border-gray-400 py-1 px-6"
                  key={item.day}
                >
                  <div className="flex">Dan {item.day}.</div>
                  <div className="flex ml-auto mr-0 gap">
                    <p className="px-1">
                      {dayjs(item.spavanje.vrijeme).format("HH:mm")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex my-2">
            <button
              className="btn bg-gray-700 ring-gray-400 mx-auto"
              onClick={() => setPreview(true)}
            >
              Pregled mokrenja i pijenja
            </button>
          </div>
          <div>
            <button className="btn btn-primary w-full my-2">Pošalji</button>
          </div>
          {preview ? (
            <Modal open={preview}>
              <Preview data={{ res: data }} setPreview={setPreview} />
            </Modal>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
};

export default ArchivedJournal;
