import { forwardRef, useState } from "react";
import dayjs from "dayjs";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayJsUtils from "@date-io/dayjs";
import hr from "dayjs/locale/hr";
import classNames from "classnames";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const handleFocus = (event) => event.target.select();

const CustomInput = (props) => {
  return (
    <>
      <input
        {...props}
        className={classNames({
          [`input`]: true,
          [`input-error`]: props.error ? true : false,
        })}
        name={props.name}
        ref={props.inputRef}
        onFocus={(e) => {
          handleFocus(e);
          props.setFocus(true);
        }}
        onBlur={() => props.setFocus(false)}
        value={props.value}
        onChange={props.onChange}
      />
      <span className="error">{props?.helperText}</span>
    </>
  );
};

const TimePicker = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocus] = useState(false);
  return (
    <MuiPickersUtilsProvider
      className="datepicker"
      utils={DayJsUtils}
      locale={hr}
    >
      <div className="relative flex-grow-1 w-100">
        <KeyboardTimePicker
          {...props}
          inputRef={ref}
          cancelLabel="Odustani"
          okLabel="Ok"
          open={isOpen}
          mask="__:__"
          invalidDateMessage="Neispravno vrijeme"
          isFocused={isFocused}
          invalidLabel="Erorr"
          setFocus={setFocus}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          TextFieldComponent={CustomInput}
        />

        {props.variant !== "static" ? (
          <span
            className=" absolute right-0 bottom-0 top-0 text-lg p-4 text-black dark:text-white cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
});

export default TimePicker;
