import { forwardRef, useState } from "react";
import dayjs from "dayjs";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayJsUtils from "@date-io/dayjs";
import hr from "dayjs/locale/hr";
import classNames from "classnames";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const handleFocus = (event) => event.target.select();

const CustomInput = (props) => {
  return (
    <input
      {...props}
      className={classNames({
        [`input`]: true,
        [`input-error`]: props.error ? true : false,
      })}
      inputMode="numeric"
      name={props.name}
      ref={props.inputRef}
      onFocus={(e) => {
        handleFocus(e);
        props.setFocus(true);
      }}
      onBlur={() => props.setFocus(false)}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const Datepicker = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocus] = useState(false);
  return (
    <MuiPickersUtilsProvider
      className="datepicker"
      utils={DayJsUtils}
      locale={hr}
    >
      <div className="relative flex-grow-1 w-100">
        {props.pickerType === "pure" ? (
          <DatePicker
            {...props}
            inputRef={ref}
            cancelLabel="Odustani"
            okLabel="Ok"
            open={isOpen}
            invalidDateMessage="Neispravan datum"
            isFocused={isFocused}
            invalidLabel="Erorr"
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            TextFieldComponent={CustomInput}
          />
        ) : (
          <KeyboardDatePicker
            {...props}
            inputRef={ref}
            cancelLabel="Odustani"
            okLabel="Ok"
            open={isOpen}
            invalidDateMessage="Neispravan datum"
            isFocused={isFocused}
            invalidLabel="Erorr"
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            TextFieldComponent={CustomInput}
          />
        )}
        <span
          className=" absolute right-0 bottom-0 top-0 text-lg p-4 text-black dark:text-white cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
    </MuiPickersUtilsProvider>
  );
});

export default Datepicker;
