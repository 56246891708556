import { useState } from "react";
import { makeStyles, Modal, Popover } from "@material-ui/core";
import { Close, Female, Male } from "../assets/images/svgs";
import store from "store2";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 220,
    width: "100%",
  },
  paper: {
    maxWidth: 220,
    width: "100%",
  },
});

const ProfileCard = ({ item }) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pickedEl, setPickedEl] = useState({});
  const [deleteEl, setDeleteEl] = useState(false);

  const queryClient = useQueryClient();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteProfile = async () => {
    const profiles = await store.get("profiles");
    const filteredProfiles = profiles.filter(
      (profile) => profile.id !== pickedEl.id
    );
    await store.set("profiles", filteredProfiles);
    queryClient.invalidateQueries("profiles");
    setDeleteEl(false);
    handleClose();
  };

  const setEdit = () => {
    store("editProfile", pickedEl);
    history.push("/novi-profil");
  };

  const classes = useStyles();

  return (
    <div className="w-full">
      <div
        className="card-xs w-full flex my-2"
        onClick={(e) => {
          handleClick(e);
          setPickedEl(item);
        }}
      >
        <div className="w-3/12 flex items-center">
          <div className="w-14">
            {item.spol.value === "muski" ? <Male /> : <Female />}
          </div>
        </div>
        <div className="w-9/12">
          <h4 className="font-semibold text-md text-darkT-500">{item.ime}</h4>
          <h5 className="text-sm text-darkT-200">Dob: {item.dob}</h5>
          <h5 className="text-sm text-darkT-200">
            Tjelesna masa: {item.masa} kg
          </h5>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{ root: classes.root, paper: classes.paper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="w-full max-w-sm">
          <div
            className="p-2 hover:bg-blue-600 hover:text-white active:bg-blue-600 active:text-white cursor-pointer"
            onClick={setEdit}
          >
            Uredi
          </div>
          <div
            className="p-2 hover:bg-red-600 hover:text-white active:bg-red-600 active:text-white cursor-pointer"
            onClick={() => setDeleteEl(true)}
          >
            Obriši
          </div>
          <div
            className="p-2 hover:bg-gray-600 hover:text-white active:bg-gray-600 active:text-white cursor-pointer"
            onClick={handleClose}
          >
            Odustani
          </div>
        </div>
      </Popover>
      {pickedEl.id ? (
        <Modal open={deleteEl}>
          <div style={{ marginTop: "70%" }} className="px-2 outline-none">
            <div className="flex justify-center flex-col bg-white p-2 rounded">
              <div className="flex self-end">
                <div className="p-2">
                  <Close
                    className="cursor-pointer w-5 h-5"
                    onClick={() => setDeleteEl(false)}
                  />
                </div>
              </div>
              <h5 className="font-semibold text-lg">
                Jeste li sigurni da želite obrisati profil?
              </h5>
              <div className="mt-1">Ime: {pickedEl.ime}</div>
              <div>Spol: {pickedEl.spol.label}</div>
              <div>Dob: {pickedEl.dob}</div>
              <div>Tjelesna masa: {pickedEl.masa} kg</div>
              <div className="mx-auto mt-3 flex gap-2">
                <button
                  className="btn btn-danger mx-auto"
                  onClick={deleteProfile}
                >
                  Obriši
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => setDeleteEl(false)}
                >
                  Odustani
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
export default ProfileCard;
