import { forwardRef } from "react";
import Select from "react-select";

const CustomSelect = forwardRef((props, ref) => {
  return (
    <Select
      {...props}
      ref={ref}
      classNamePrefix={"custom-select"}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
});

export default CustomSelect;
