import { Fragment, useEffect, useState } from "react";
import ShareIcon from "./ShareIcon";
import HomeScreenIcon from "./HomeScreenIcon";

import styles from "./PWAPrompt.styles.scss";
import classNames from "classnames";

const PWAPrompt = ({
  delay,
  copyTitle,
  copyBody,
  copyAddHomeButtonLabel,
  copyShareButtonLabel,
  copyClosePrompt,
  permanentlyHideOnDismiss,
  promptData,
  maxVisits,
  onClose,
}) => {
  const [isVisible, setVisibility] = useState(!Boolean(delay));

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        // Prevent keyboard appearing over the prompt if a text input has autofocus set
        if (document.activeElement) {
          document.activeElement.blur();
        }

        setVisibility(true);
      }, delay);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(styles.noScroll);
    }
  }, [isVisible]);

  const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);
  const visibilityClass = isVisible ? styles.visible : styles.hidden;
  const iOSClass = isiOS13AndUp ? styles.modern : "legacy";

  const dismissPrompt = (evt) => {
    document.body.classList.remove(styles.noScroll);
    setVisibility(false);

    if (permanentlyHideOnDismiss) {
      localStorage.setItem(
        "iosPwaPrompt",
        JSON.stringify({
          ...promptData,
          visits: maxVisits,
        })
      );
    }

    if (typeof onClose === "function") {
      onClose(evt);
    }
  };

  const onTransitionOut = (evt) => {
    if (!isVisible) {
      evt.currentTarget.style.display = "none";
    }
  };

  return (
    <Fragment>
      <div
        className={classNames({
          [`bg-black bg-opacity-60 min-h-screen fixed left-0 top-0 w-screen z-40 transition-opacity ease-in`]: true,
          [`hidden`]: isVisible ? false : true,
        })}
        aria-label="Close"
        role="button"
        onClick={dismissPrompt}
        onTransitionEnd={onTransitionOut}
      />
      <div
        className={classNames({
          [`backdrop-blur rounded-md text-black bg-gray-300 z-50 bg-opacity-80 mx-8 brightness-125 fixed bottom-0 left-0 `]: true,
          [`hidden`]: isVisible ? false : true,
        })}
        aria-describedby="pwa-prompt-description"
        aria-labelledby="pwa-prompt-title"
        role="dialog"
        onTransitionEnd={onTransitionOut}
      >
        <div className="border-b border-gray-500 flex items-center flex-row flex-nowrap justify-between p-3">
          <p id="pwa-prompt-title" className="text-lg font-medium">
            {copyTitle}
          </p>
          <button
            className="text-blue-700 text-base bg-transparent"
            onClick={dismissPrompt}
          >
            {copyClosePrompt}
          </button>
        </div>
        <div className="flex w-full">
          <div className="w-full border-b border-gray-500 p-4">
            <p id="pwa-prompt-description" className="text-sm m-0 p-0">
              {copyBody}
            </p>
          </div>
        </div>

        <div className="mx-4 p-4">
          <div className="flex items-center flex-nowrap justify-start text-left mb-4">
            <ShareIcon
              className=" h-8 mr-8 w-6 text-blue-700 fill-current"
              modern={isiOS13AndUp}
            />
            <p className="text-sm m-0 p-0">{copyShareButtonLabel}</p>
          </div>

          <div className="flex items-center flex-nowrap justify-start text-left">
            <HomeScreenIcon
              className=" h-8 mr-8 w-9 text-gray-600 fill-current"
              modern={isiOS13AndUp}
            />
            <p className="text-sm m-0 p-0">{copyAddHomeButtonLabel}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PWAPrompt;
