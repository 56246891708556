import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import store from "store2";
import { Journals2, Add } from "../../assets/images/svgs";
import Alert from "../../components/Alert";
import JournalCard from "../../components/JournalCard";

const Journals = () => {
  const { data, isFetching } = useQuery(
    "journals",
    async () => {
      const res = await store.get("journals");
      return res ? res : [];
    },
    {}
  );
  const { data: activeJournal } = useQuery("activeJournal", async () => {
    const res = await store.get("activeJournal");
    if (res?.hasOwnProperty("id")) {
      return res;
    } else return false;
  });

  return (
    <>
      <h2 className="font-semibold text-3xl my-4 text-darkT-500">Dnevnici</h2>
      <div className="max-w-255 flex items-center mx-auto mt-11">
        <Journals2 width="255px" />
      </div>
      <div>
        <h5 className="text-sm text-darkT-200 text-center">
          Don’t worry, It’s easy & takes only a few minutes
        </h5>
      </div>
      <div className="flex mt-2 flex-wrap">
        {activeJournal ? (
          <div className="w-full mt-4">
            <Alert
              type="info"
              text="Aktivni dnevnik već postoji"
              noClose
              noMb
            />
          </div>
        ) : null}
        <div className="my-4 w-full">
          <Link to="/novi-dnevnik">
            <button
              disabled={activeJournal}
              className="btn btn-primary w-full relative flex"
            >
              <Add className="absolute self-center" />
              <p className="mx-auto">Otvori novi dnevnik</p>
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full mx-auto flex flex-wrap mt-2">
        {isFetching ? (
          <div></div>
        ) : (
          data.length > 0 &&
          data
            .sort((a, b) =>
              dayjs(a.datumPocetka).isBefore(dayjs(b.datumPocetka))
                ? 1
                : dayjs(b.datumPocetka).isBefore(a.datumPocetka)
                ? -1
                : 0
            )
            .map((item) => {
              return <JournalCard item={item} key={nanoid()} />;
            })
        )}
      </div>
    </>
  );
};

export default Journals;
